<template>
    <div class="body-form">
      <Loading v-if="isLoading" />
      <div class="cart-form">
        <h1 style="text-align: center; margin-bottom: 40px; padding: 20px">
          Edit Transaksi
        </h1>
        <div class="form-ctn">
          <div class="invoice">
            <div class="content-form">
              <p>No. Invoice</p>
              <div class="form-info">
                <p>{{ form.invoice_number }}</p>
              </div>
            </div>
            <div class="content-form">
              <p>Tanggal Pembelian</p>
              <div class="form-info">
                <p>{{ form.created_at }}</p>
              </div>
            </div>
          </div>
  
          <div class="section-line"></div>
          <div class="customer">
            <h3 @click="toggleCollapse('customer')">
              Pelanggan
              <span>
                <CollapsedArrow
                :on="collapsed.customer"
                style="margin-right: 10px"
              />
              </span>
            </h3>
            <div class="content-form" v-show="!collapsed.customer">
              <p>Nama Lengkap</p>
              <div class="form-info">
                <p>{{ form.full_name }}</p>
              </div>
            </div>
  
            <div class="content-form" v-show="!collapsed.customer">
              <p>No. Handphone</p>
              <v-row>
                <v-col cols="2" class="phone">
                  <p>Kode Negara</p>
                  <div class="phone-logo">
                    <IndonesiaFlag :width="27" :height="18" />
                    <p>+62</p>
                  </div>
                </v-col>
                <v-col>
                  <div class="form-info">
                    <p>{{ form.phone_number }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div class="content-form" v-show="!collapsed.customer">
              <p>Email</p>
              <div class="form-info">
                <p>{{ form.email }}</p>
              </div>
            </div>
          </div>
          <div class="section-line"></div>
          <div class="product">
            <h3 @click="toggleCollapse('product')">
              Produk
              <span>
                <CollapsedArrow
                :on="collapsed.product"
                style="margin-right: 10px"
              />
              </span>
            </h3>
            <div class="content-form" v-show="!collapsed.product">
              <p>Program</p>
              <div class="form-info">
                <p>{{ form.class.name }}</p>
              </div>
            </div>
            <div class="content-form" v-show="!collapsed.product">
              <p>ID Produk</p>
              <div class="form-info">
                <p>{{ form.class.id }}</p>
              </div>
            </div>
            <div class="content-form" v-show="!collapsed.product">
              <p>Harga</p>
              <div class="form-info">
                <p>{{ formatCurrency(form.price, false) }}</p>
              </div>
            </div>
          </div>
          <div class="section-line"></div>
          <div class="payment">
            <h3 @click="toggleCollapse('payment')">
              Produk
              <span>
                <CollapsedArrow
                :on="collapsed.payment"
                style="margin-right: 10px"
              />
              </span>
            </h3>
            <div class="content-form" v-show="!collapsed.payment">
              <p>Kode Voucher</p>
              <div class="form-info">
                <p>{{ form.voucher_code }}</p>
              </div>
            </div>
            <div class="content-form" v-show="!collapsed.payment">
              <p>Diskon</p>
              <div class="form-info">
                <p style="color: #b80f0a">
                  {{ formatCurrency(form.discount, true) }}
                </p>
              </div>
            </div>
            <div class="content-form" v-show="!collapsed.payment">
              <p>Total Pembayaran</p>
              <div class="form-info">
                <p style="color: #162ca2">
                  {{ formatCurrency(form.total_price, false) }}
                </p>
              </div>
            </div>
            <div class="content-form">
              <p>Status pembayaran</p>
              <v-select
                label="Status pembayaran"
                v-model="form.status"
                :items="statusItems"
                :item-text="'name'"
                :item-value="'value'"
                class="form-input"
                solo
              />
            </div>
            <div class="content-form">
              <p>Attachment</p>
              <div class="form-info">
                <p>{{ form.url }}</p>
              </div>
            </div>
          </div>
  
          <div class="submit-box" v-if="!isWatch">
            <Button
              name="SIMPAN PERUBAHAN"
              width="260px"
              @click="onSubmit"
              :disabled="originalStatus == form.status"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import Loading from "@/components/ilustration/Loading.vue";
  import IndonesiaFlag from "@/components/icons/IndonesiaFlag.vue";
  import CollapsedArrow from "@/components/icons/CollapsedArrow.vue";
  import Button from "@/components/Button.vue";
  
  export default {
    name: "TransactionAccelerationForm",
    data() {
      return {
        originalStatus: "",
        collapsed: {
          customer: false,
          product: false,
          payment: false
        },
        id: null,
        modal: false,
        name: "",
        isLoading: false,
        isWatch: false,
        isEdit: false,
        statusItems: [
          {
            name: "Draft",
            value: "DRAFT",
          },
          {
            name: "Menunggu Pembayaran",
            value: "WAITING_FOR_PAYMENT",
          },
          {
            name: "Pembayaran Lunas",
            value: "PAID",
          },
          {
            name: "Cancel",
            value: "CANCEL",
          },
          {
            name: "Expired",
            value: "EXPIRED",
          },
        ],
        form: {
          birthdate: "",
          created_at: "",
          discount: "",
          email: "",
          full_name: "",
          invoice_number: "",
          is_paid: "",
          phone_number: "",
          gender: "",
          total_price: "",
          voucher_code: "",
          type_event: "",
          url: "",
          payment_link_id: "",
          status: "",
          class: {
            method: "",
            name: "",
            price: "",
            id: 0,
          },
        },
      };
    },
    components: {
      Loading,
      Button,
      IndonesiaFlag,
      CollapsedArrow
    },
    methods: {
      checkingType() {
        this.name = this.$route.meta.name;
        this.isWatch = this.$route.path.lastIndexOf("watch") >= 0;
        this.isEdit = this.$route.path.lastIndexOf("edit") >= 0;
        this.id = this.$route.params.id;
        if (!this.id) return;
        this.getDataTransactionAcceleration();
      },
      async getDataTransactionAcceleration() {
        this.isLoading = true;
        try {
          const resp = await this.$web_http.get(
            `/v1/transaction/${this.id}?category=ACCELERATION_CLASS`
          );
          const data = resp.data.data.transaction;
          this.form = {
            ...data,
            is_paid: data.is_paid ? "Sudah membayar" : "Belum membayar",
            birthdate: this.$helpers.formatDate(data.birthdate),
            created_at:
              this.$helpers.formatDateWithTimeAndTimeZone(data.created_at) +
              "WIB",
          };
          this.originalStatus = this.form.status;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async onSubmit() {
        this.isLoading = true;
        try {
          await this.$web_http.patch(
            `/v1/transaction/status/${this.id}`, {
              status: this.form.status,
              payment_link_id: this.form.payment_link_id
            }
          );
          setTimeout(() => {
            this.$router.push("/b2b/acceleration");
          }, 2000);
        } catch (error) {
            console.log(error);
          alert("harap isi semua field yang tersedia");
          this.isLoading = false;
        }
      },
      formatCurrency(amount, isDiscount) {
        return this.$helpers.formatCurrency(amount, isDiscount);
      },
      toggleCollapse(section) {
        // Use the provided section parameter to toggle the corresponding class
        this.collapsed[section] = !this.collapsed[section];
      },
    },
    mounted() {
      this.checkingType();
    },
  };
  </script>
  <style scoped>
  .body-form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafaf9;
    padding: 50px 0;
    min-height: 100vh;
    width: 100vw;
  }
  .form-ctn {
    padding: 0 40px;
  }
  .cart-form {
    background-color: white;
  
    position: relative;
    width: 50%;
    border-radius: 30px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
  .back-icon {
    position: fixed;
    top: 30px;
    left: 30px;
    cursor: pointer;
    color: rgb(198, 198, 198);
  }
  .form-input {
    border-radius: 14px !important;
    background-color: transparent !important;
  }
  .no-space {
    margin-bottom: -20px;
  }
  .content-form {
    margin-top: -5px;
  }
  .content-form p {
    font-size: 16px;
    margin: 3px 0;
    font-weight: bold;
  }
  .berkas-title {
    font-size: 18px;
    margin: 20px 0;
    font-weight: bold;
  }
  .watch-ctn {
    padding: 15px;
    border-radius: 15px;
    border: 1px solid gray;
  }
  
  .spacing-ctn {
    margin-bottom: 30px;
    width: 100%;
  
    background-color: #f5f6fa;
    height: 10px;
  }
  .submit-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  .alert-info {
    font-size: 14px;
    color: #7b7e8c;
  }
  .list-event {
    margin-top: 15px;
    position: relative;
    margin-left: 60px;
  }
  .icon-plus {
    position: absolute;
    bottom: 20px;
    left: -56px;
    cursor: pointer;
  }
  .add-button {
    margin: 15px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .add-button .line {
    width: 40%;
    background-color: #d3d6e1;
    height: 2px;
  }
  .add-button .button {
    min-width: max-content;
    padding: 7px 20px;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid #d3d6e1;
  }
  .label-info {
    color: gray;
    font-weight: normal;
    font-size: 15px;
  }
  .section-line {
    width: 100%;
    height: 1px;
    background: #acafbf;
    margin-bottom: 10px;
  }
  
  .submit-box {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .phone {
    width: 130px;
    height: 50px;
    border-radius: 10px;
    margin-top: 12px;
    margin-left: 12px;
    background: var(--Ghost-White, #f7f9fd);
  }
  .phone p {
    margin-top: -9px;
    font-size: 10px;
    color: #575966;
  }
  .phone-logo {
    margin-top: -5px;
    display: flex;
    align-items: center;
    background: #f7f9fd;
    width: 70px;
    height: 35px;
  }
  
  .phone-logo p {
    margin-bottom: 7px;
    margin-left: 3px;
    margin-top: 4px;
  }
  
  .form-info {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 14px 20px;
    margin-bottom: 20px;
    align-items: center;
    border-radius: 15px;
    background: var(--Ghost-White, #f7f9fd);
  }
  
  .form-info p {
    font-weight: normal !important;
    color: #36373f;
  }
  
  .customer h3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .customer.collapsed .content-form {
    max-height: 0;
    overflow: hidden;
  }
  
  .product h3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .product.collapsed .content-form {
    max-height: 0;
    overflow: hidden;
  }
  
  .payment h3 {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .payment.collapsed .content-form {
    max-height: 0;
    overflow: hidden;
  }
  
  @media screen and (max-width: 1100px) {
    .cart-form {
      width: 70%;
    }
  }
  </style>
  